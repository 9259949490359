import { useTranslation } from 'react-i18next';
import { useAppHeaderViewModel } from 'NavigationHelpers';
import { Outlet } from 'react-router-dom';
import { useCurrentUser } from 'FrontRoyalAngular';
import { getCohort } from 'Users';
import { CategoryMenu } from '../CategoryMenu';
import { Footer } from '../Footer';
import { WelcomeMessage } from '../WelcomeMessage';

export function Layout() {
    const currentUser = useCurrentUser();
    const cohort = getCohort(currentUser);
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { t } = useTranslation();

    AppHeaderViewModel.setBodyBackground('beige');
    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(t('resources.resources.resources_title').toLocaleUpperCase());

    if (!cohort) return null;

    return (
        <div className="flex animate-fade-in flex-col sm:min-h-[calc(100vh-75px)]">
            <div className="flex-shrink-0 bg-coral py-[14px] valar:bg-blue sm:py-7.5">
                <CategoryMenu cohort={cohort} />
            </div>
            <div className="relative flex-grow">
                <Outlet />
                <WelcomeMessage />
            </div>
            <Footer className="flex-shrink-0" />
        </div>
    );
}
