import { Link as ReactRouterDomLink } from 'react-router-dom';
import { type AnyObject } from '@Types';
import { Icon } from 'FrontRoyalIcon';
import { cva } from 'class-variance-authority';
import { type ReactNode } from 'react';
import { useLinkParams, type LinkTarget } from 'NavigationHelpers';

const anchorLink = cva(null, {
    variants: {
        withArrow: {
            true: 'inline-flex gap-[0.35rem]',
        },
        color: {
            brand: 'text-coral hover:text-coral-dark valar:text-blue valar:hover:text-blue-dark',
            'lightish-blue': 'text-lightish-blue',
        },
        allowWrap: {
            true: 'inline',
        },
    },
    defaultVariants: {
        withArrow: false,
        color: 'lightish-blue',
    },
    compoundVariants: [
        {
            color: 'lightish-blue',
            withArrow: false,
            className: 'hover:underline',
        },
        {
            withArrow: true,
            className: 'hover:no-underline',
        },
    ],
});

type AnchorLinkProps = {
    state: AnyObject;
    withArrow?: boolean;
    color?: 'brand' | 'lightish-blue';
    className?: string;
    target?: LinkTarget;
    to?: string;
    replace?: boolean;
    children: ReactNode;
    allowWrap?: boolean;
};

/**
 * @deprecated Use ClickableLink instead.
 * See https://trello.com/c/lETaUDCA for an explanation of why/where this component is still used and
 * what the plan is for it moving forward.
 */
export function AnchorLink(props: AnchorLinkProps) {
    const { children, withArrow, color, className, to, state, replace, allowWrap } = props;

    if (allowWrap && withArrow) {
        throw new Error('Links with arrows cannot be configured to wrap');
    }

    let { target } = props;
    ({ target } = useLinkParams({ href: to, target }));

    return (
        <ReactRouterDomLink
            className={anchorLink({ withArrow, color, className, allowWrap })}
            target={target}
            to="."
            replace={replace}
            state={state}
        >
            {children}
            {withArrow === true ? <Icon iconName="RightArrow16" className="rtl:rotate-180" /> : null}
        </ReactRouterDomLink>
    );
}
